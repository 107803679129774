import { NextPage } from "next";
import React from "react";

interface Props {
  children?: any;
  ngClass?: string;
  style?:any
}

interface HeadDescProps {
  topHeading: string;
  heading: string;
  description: string;
  ngClass?: string;
  children: any;
}


const BlankSection: NextPage<Props> = ({ children='', ngClass, style }) => {
  return (
    <section className={ngClass} style={style}>
      <div className="py-8 px-6 mx-auto max-w-7xl sm:py-10 lg:px-8">
        {children}
      </div>
    </section>
  );
}

const SectionWithHeading: NextPage<any> = ({ heading, ngClass, children }) => {
  return (
    <BlankSection ngClass={ngClass}>
      <div className="text-center mb-12 lg:mb-24">
        <h1 className="font-bold text-4xl lg:text-4xl tracking-tight text-gray-900 relative">
          {heading}
        </h1>
      </div>
      <div>{children}</div>
    </BlankSection>
  );
}

const SectionWithHeadingDesc: NextPage<HeadDescProps> = ({ topHeading, heading, description, ngClass, children }) => {
  return (
    <BlankSection ngClass={ngClass}>
      <div className='sm:px-4 w-full text-center mt-10'>
        <h1 className="md:text-header text-header-mbl font-sourcesans relative">
            <span className="text-secondary-500 after:content-[''] after:absolute after:w-24 md:after:w-32 after:h-1 md:after:h-2 after:top-[50px] md:after:top-[65px] after:left-[40%] md:after:left-[45%] after:border-4 md:after:border-4 after:border-secondary-500 after:rounded">
              <span className="text-primary-500 mr-4">{topHeading}</span>
              <span className="text-orange mr-2">{heading}</span>
            </span>
          </h1>
        <p className='text-primary-500 md:text-body text-body-mbl py-4 mt-10 px-4 md:px-[16rem]'>{description}</p>
      </div>
      <div>{children}</div>
    </BlankSection>
  );
}

const SectionWithTopBottomHeadingDesc: NextPage<HeadDescProps> = ({ topHeading, heading, description, ngClass, children }) => {
  return (
    <BlankSection ngClass={ngClass}>
      <div className='sm:px-4 w-full text-center'>
        <h1 className='md:text-header text-header-mbl font-sourcesans relative'>
          <span className="block">{topHeading}</span>{' '}
          <span className="text-secondary-500 after:content-[''] after:absolute after:w-24 md:after:w-32 after:h-1 md:after:h-2 after:top-[100px] md:after:top-[150px] after:left-[40%] md:after:left-[45%] after:border-4 md:after:border-4 after:border-secondary-500">{heading}</span>
        </h1>

        <p className='text-primary-500 md:text-body text-body-mbl py-12 px-4 md:px-[16rem]'>{description}</p>
      </div>
      <div>{children}</div>
    </BlankSection>
  );
}

const BlankSectionNoYSpacing: NextPage<Props> = ({ children, ngClass }) => {
  return (
    <section className={ngClass}>
      <div className="px-4 mx-auto max-w-7xl lg:px-8">{children}</div>
    </section>
  );
}

const BlankSectionNoXSpacing: NextPage<Props> = ({ children, ngClass }) => {
  return (
    <section className={ngClass}>
      <div className="py-8 xl:py-16">{children}</div>
    </section>
  );
}

const BlankSectionNoSpacing: NextPage<Props> = ({ children, ngClass }) => {
  return (
    <section className={ngClass}>
      <div>{children}</div>
    </section>
  );
}


export {
  BlankSection,
  SectionWithHeading,
  SectionWithHeadingDesc,
  BlankSectionNoYSpacing,
  SectionWithTopBottomHeadingDesc,
  BlankSectionNoXSpacing,
  BlankSectionNoSpacing,
};
